import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/templates/common/Hero";
import Content from "../../sections/templates/career/Content";
import Feature from "../../sections/templates/career/Feature";
import Roles from "../../sections/templates/career/Roles";

const Career = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero title="Career">
          Create custom landing pages with Omega that converts more visitors
          than any website.
        </Hero>
        <Content />
        <Feature />
        <Roles />
      </PageWrapper>
    </>
  );
};
export default Career;
